import React, { useState, useEffect,
                useImperativeHandle, forwardRef } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { intersection } from 'lodash-es';

import LoadingSpinner from './LoadingSpinner.jsx';
import { strRequestJSON } from './util.jsx';

const NULLALBUM = {
    value: null,
    label: 'Please choose an existing album',
};

function getExistingOrNullAlbum(albumOptions, targetAlbumName) {
    return albumOptions.find(el => el.label === targetAlbumName) || NULLALBUM;
}

function getAlbumOptions(albums,
                         preventAutoAlbums,
                         isAdmin,
                         autoAlbum,
                         showStyleAlbums,
                         targetAlbumName) {
    let albumOptions = [];
    if (!preventAutoAlbums || isAdmin) {
        albumOptions = albumOptions.concat([autoAlbum]);
    } else {
        albumOptions = albumOptions.concat([NULLALBUM]);
    }
    if (albums) {
        // showStyleAlbum = true, show all albums
        // showStyleAlbum = false, hide style_album == true
        const filteredAlbums = albums.filter(
            album => showStyleAlbums
                || !album.style_album
            /* if an exact match named album exists allow it no matter if
             * it's a style_album */
                || album.name === targetAlbumName
        );
        albumOptions = albumOptions.concat(filteredAlbums.map(album => {
            return {
                value: album.pk,
                label: `${album.name}${showStyleAlbums && album.style_album ? ' (auto)' : ''}`,
            };
        }));
    }
    if (isAdmin) {
        albumOptions = albumOptions.concat([{
            value: 'new',
            label: 'Create new album',
        }]);
    }
    return albumOptions;
}

function PartyAlbumTargeter(props, ref) {
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [albums, setAlbums] = useState(props.albums);
    const [isAdmin, setIsAdmin] = useState(props.isAdmin);
    const [partyName, setPartyName] = useState(props.partyName);
    const [itemPks, setItemPks] = useState(props.itemPks);
    const [quantity, setQuantity] = useState(props.quantity);
    const [quantityRequested, setQuantityRequested] = useState(props.quantity);
    const [preventAutoAlbums, setPreventAutoAlbums] = useState(props.preventAutoAlbums);
    const [isMarketParty, setIsMarketParty] = useState(false);
    const [useAlbums, setUseAlbums] = useState(false);

    const AUTOALBUM = {
        value: 'auto',
        label: `[Auto] ${props.targetAlbumName}`,
    };

    const VACUOUSALBUM = {
        value: "catch-all",
    };

    useEffect(() => {
        if (props.canFetch) fetch();
    }, [props.partyPk, props.itemPks]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        setAlbums(props.albums);
        setItemPks(props.itemPks);
        setPartyName(props.partyName);
    }, [props.albums, props.itemPks, props.partyName]);
    useEffect(() => {
        setPreventAutoAlbums(props.preventAutoAlbums);
        setIsAdmin(props.isAdmin);
        /* check if the currently selected album is in our albums still
         * and leave it if it's still good, if not then the party changed
         * via props instead of fetch and we need to update our
         * selectedAlbum */
        const filteredAlbums = props.albums && props.albums.filter(
            album => props.showStyleAlbums
                || !album.style_album
            /* if an exact match named album exists allow it no matter if
             * it's a style_album */
                || album.name === props.targetAlbumName
        );
        if (filteredAlbums && filteredAlbums.map(el => el.pk)
                                            .indexOf(selectedAlbum.value) < 0) {
            if (props.preventAutoAlbums && !props.isAdmin) {
                const albumOptions = filteredAlbums.map(album => {
                    return {
                        value: album.pk,
                        label: `${album.name}${props.showStyleAlbums && album.style_album ? ' (auto)' : ''}`,
                    };
                });
                setSelectedAlbum(getExistingOrNullAlbum(albumOptions,
                                                        props.targetAlbumName));
            } else {
                setSelectedAlbum(AUTOALBUM);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.partyPk, props.albums, props.targetAlbumName,
        props.preventAutoAlbums, props.isAdmin, props.showStyleAlbums]);

    useImperativeHandle(ref, () => ({
        addItems: () => {
            return onAddToPartyClick();
        },
        setSaving: (saving) => {
            setSaving(saving);
        },
    }));

    const url = `/api/v2/listings_smartadd/${props.partyPk}/`;
    let postData = {
        item_pks: JSON.stringify(props.itemPks),
    };
    if (props.allowProducts) {
        postData['products'] = 't';
    }
    if (props.dropship) {
        postData['dropship'] = 't';
    }
    async function fetch() {
        setLoading(true);
        const data = await strRequestJSON(url, 'POST', postData);
        setAlbums(data.albums);
        setIsAdmin(data.is_admin);
        setPreventAutoAlbums(data.prevent_auto_albums);
        setPartyName(data.party_name);
        setQuantity(data.quantity);
        setQuantityRequested(data.quantity_requested);
        setIsMarketParty(data.is_market_party);
        setUseAlbums(data.use_albums);
        if (data.is_market_party && !data.use_albums) {
            setSelectedAlbum(VACUOUSALBUM);
        } else if (data.prevent_auto_albums && !data.is_admin) {
            const freshAlbumOptions = getAlbumOptions(
                data.albums,
                data.prevent_auto_albums,
                data.is_admin,
                AUTOALBUM,
                props.showStyleAlbums,
                props.targetAlbumName,
            );
            setSelectedAlbum(getExistingOrNullAlbum(freshAlbumOptions,
                                                    props.targetAlbumName));
        } else {
            setSelectedAlbum(AUTOALBUM);
        }
        /* make sure props.itemPks are not in the party already so that
         * our count of what is being added is accurate */
        const unpartiedItemPks = data.items.map(i => i.pk);
        setItemPks(intersection(props.itemPks, unpartiedItemPks));
        setLoading(false);
    }

    const albumOptions = getAlbumOptions(albums,
                                         preventAutoAlbums,
                                         isAdmin,
                                         AUTOALBUM,
                                         props.showStyleAlbums,
                                         props.targetAlbumName);
    const [selectedAlbum, setSelectedAlbum] = useState(albumOptions[0]);
    const [newAlbumName, setNewAlbumName] = useState('');

    const disabled = loading || saving || itemPks.length === 0;
    let itemCount = props.quantity;
    if (props.canFetch) {
        // with no itemPks the quantity (from the api call) is all unpartied
        // items
        itemCount = (itemPks.length > 0 || props.quantity)
                  ? quantity : 0;
    }
    const className = itemCount === 0 ? 'default' : 'primary';

    const saveBtnDisabled = disabled
                         || (selectedAlbum.value === 'new' && !newAlbumName)
                         || (!selectedAlbum.value)
                         || (selectedAlbum.value === 'auto' && preventAutoAlbums && !isAdmin);

    function onChangeAlbum(event) {
        setSelectedAlbum(event);
    }

    function onAddToPartyClick(e=null) {
        if (saveBtnDisabled)
            return;
        if (e)
            e.preventDefault();
        setSaving(true);
        const data = {
            action: 'add_items',
            target_album: selectedAlbum.value,
            new_album_name: newAlbumName,
            item_pks: JSON.stringify(itemPks),
            dropship: !!props.dropship,
        };
        return $.post(url, data, (data, status, xhr) => {
            if (e) {
                toast.success(`Added ${data.success_count} item${data.success_count === 1 ? '' : 's'}. Reloading page...`);
                if (data.failure_count > 0)
                    toast.error(`${data.failure_count} failed to add. (Albums may have been deleted while adding)`);
            }
            setNewAlbumName('');
            if (e) {
                /* no callback for programmed clicks, refreshment handled
                 * in the caller */
                props.callback();
                setSaving(false);
            }
        }).fail((xhr, status, err) => {
            const errMessage = xhr.responseJSON?.error;
            if (errMessage) {
                toast.error(`Error adding items: ${errMessage}`);
            } else {
                toast.error(`There was an error adding ${e ? props.targetAlbumName + ' ' : ''}items.`);
            }
            setSaving(false);
        });
    }

    let saveBtnText = '';
    if (saving) {
        saveBtnText = <span><LoadingSpinner /> Saving... </span>;
    } else if (loading) {
        saveBtnText = <span>Loading...</span>;
    } else {
        saveBtnText = `Add ${itemCount} item${itemCount === 1 ? '' : 's'} to ${StrUserInfo.words.party.toLowerCase()}`;
        if (partyName) {
            saveBtnText = `${saveBtnText}: ${partyName}`;
        }
    }
    let saveBtnTitle = '';
    if (quantity < quantityRequested) {
        saveBtnTitle = `Only adding items not in the party already, adding ${quantity} out of ${quantityRequested} selected`;
    }
    return (
        <div style={{minWidth: '200px'}}>
          {(isMarketParty && !useAlbums) ||
           <React.Fragment>
             <Select options={albumOptions}
                     isDisabled={disabled}
                     placeholder="Pick an album"
                     value={selectedAlbum}
                     onChange={onChangeAlbum} />
             {selectedAlbum.value === 'new' &&
              <input className="form-control"
                     name="newAlbumName"
                     autoFocus
                     style={{marginTop: '5px'}}
                     placeholder="Enter new album name"
                     value={newAlbumName}
                     onChange={(e) => setNewAlbumName(e.target.value)} />
             }
           </React.Fragment>
          }
          <button className={`btn btn-${className}`}
                  style={{marginTop: '5px'}}
                  onClick={onAddToPartyClick}
                  title={saveBtnTitle}
                  disabled={saveBtnDisabled}>
            {saveBtnText}
          </button>
        </div>
    );
}

PartyAlbumTargeter.defaultProps = {
    isAdmin: false,
    preventAutoAlbums: false,
    showStyleAlbums: true,
    quantity: 0,
};

PartyAlbumTargeter.propTypes = {
    partyPk: PropTypes.number.isRequired,
    albums: PropTypes.array,
    itemPks: PropTypes.array.isRequired,
    quantity: PropTypes.number,
    targetAlbumName: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool,
    callback: PropTypes.func.isRequired,
    partyName: PropTypes.string,
    canFetch: PropTypes.bool,
    preventAutoAlbums: PropTypes.bool,
    showStyleAlbums: PropTypes.bool,
    allowProducts: PropTypes.bool,
    dropship: PropTypes.bool,
};

// https://reactjs.org/docs/hooks-reference.html#useimperativehandle
// eslint-disable-next-line no-func-assign
PartyAlbumTargeter = forwardRef(PartyAlbumTargeter);

export default PartyAlbumTargeter;

let appRoot = null;

export function PartyAlbumTargeterApp(el, data) {
    if (el === null)
        return;
    if (appRoot)
        appRoot.unmount();
    appRoot = createRoot(el);
    appRoot.render(<PartyAlbumTargeter partyPk={data.partyPk}
                                       albums={data.albums}
                                       itemPks={data.itemPks}
                                       targetAlbumName={data.targetAlbumName}
                                       isAdmin={data.isAdmin}
                                       partyName={data.partyName}
                                       canFetch={data.canFetch}
                                       allowProducts={data.allowProducts}
                                       callback={data.callback}
                                       dropship={data.dropship} />);
}
